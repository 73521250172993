.footer {
  background-color: rgb(200, 188, 167, 0.6);
  padding: 1rem;
  margin-top: 1rem;
  border-top: 1px solid grey;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.creator-info-container {
  display: flex;
  flex-direction: row;
}

.creator-info-container p {
  margin: 0rem 0rem 0.5rem;
  padding: 0rem 0.5rem 0rem;
  display: flex;
  flex-direction: row;

}

.footer-link {
  padding: 0rem 1rem;
  text-decoration: none;
  color: black;
}