.about-us-card {
	margin: 2rem;
	padding: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
  box-shadow: #0000001f 6px 6px;
  
}

.about-us-card h3 {
	border-bottom: 2px solid transparent;
	border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}

.about-us-image {
	padding: 0.5rem;
	margin: 0.5rem;
}

.about-us-text {
	padding: 0.5rem;
	margin: 0.5rem;
	width: 80%;
	text-align: center;
}

@media only screen and (max-device-width: 776px) {
	.about-us-text {
		width: 90%;
	}
}
