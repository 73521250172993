.my-navbar {
 border-bottom: 5px solid rgb(200, 188, 167, 0.6);

 z-index: 1000; 
 width: 100%;
 background-color: white;
}

.navbar-inner-container a.active {
  border-bottom: 2px solid transparent;
  border-image-source: url('../../assets/SVG/active.svg');
  border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}
.navbar-inner-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  
}

.link-container {
  display: flex;
  align-items: center;
}
.navbar-link {
  padding: 1rem;
  color: black;
  text-decoration: none;
}

.dropdown-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  
}

.dropdown {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
  border: 1px solid transparent;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #5f8fa5d8;
  color: white;
  position: absolute;
  z-index: 800;
}

.dropdown .navbar-link {
  padding: 0.5rem;
  color: white;

}