@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  font-family: 'Nunito', serif !important;
}

html,
body {
	height: 100vh;
	width: 100vw;
	overscroll-behavior-y: auto;
}
body {
	background-color: rgba(116, 159, 177, 0.381) !important;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

