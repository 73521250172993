.home-header {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0px -50px;
	display: flex;
	justify-content: flex-start;
}

.welcome-message-container {
	height: 400px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.welcome-message-text {
	overflow-wrap: break-word;
	font-size: 4rem;
	text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.4);
	height: 20% !important;
	width: 100%;
}

@media only screen and (max-device-width: 776px) {
	.welcome-message-container {
		padding-bottom: 4rem;
		justify-content: center;
	}

	.welcome-message-container div {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.welcome-message-text {
		width: 80%;
		font-size: 2.5rem;
		text-align: center;
		text-shadow: -0.5px -0.5px 0 #747474, 0.5px -0.5px 0 #747474, -0.5px 0.5px 0 #747474, 0.5px 0.5px 0 #747474;
	}
}
