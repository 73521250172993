.captains-hideaway-header {
	background-position: 0 -250px;
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	cursor: grab;
}

.sl-scroll-lock {
	overflow-y: hidden !important;
}

.image-gallery-wrapper {
	max-width: 800px;
	width: 100%;
	margin: 5rem auto 0rem;
	border: 1px solid rgb(146, 129, 242);
	box-shadow: #2b15453d 1px 10px 10px 5px;
}

.overview-card {
	padding: 1rem;
	margin: 1rem 0rem;
	width: 100%;
}

.overview-card .card-title {
	padding: 0.5rem;
	margin: 0.5rem;
	align-self: center;
	border-bottom: 3px solid transparent;
	border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}

.overview-card .card-body {
	border: none;
}

.overview {
	padding: 0.5rem;
	margin: 0.5rem;
}

.overview-item {
	display: flex;
}

.overview-item-icon {
	padding-top: 0.5rem;
}

.overview-item p {
	padding: 0rem 0.5rem 0.5rem 0.5rem;
}

.rooms-and-beds-card {
	padding: 0.5rem;
	margin: 1rem 0rem;
}

.rooms-and-beds-card .card-body {
	padding: 0px;
}

.rooms-and-beds-inner-container {
	padding: 0.5rem;
}

.hideaway-bedroom-description-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0.5rem;
	margin: 0.5rem;
}

.bedroom-description-text {
	font-size: 14px;
}

.spaces-card {
	padding: 0.5rem;
	margin: 1rem 0rem 0.5rem 0rem;
}

.spaces-card .card-body {
	padding: 0.5rem;
}

.spaces-header-text {
	padding: 0.5rem;
}

.spaces-text {
	font-size: 16px;
}

.spaces-item-container {
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 0.5rem;
}

.spaces-item-container p {
	margin: 0rem 0.5rem;
	padding: 0;
}

.captains-hideaway-card-body {
	border-left: 3px solid transparent;
	border-image-source: linear-gradient(white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}

.about-property-card {
	margin: 0.5rem;
}
.about-property-subheader {
	font-size: 16px;
}

.about-property-text {
	font-size: 14px;
}

.amenities-card {
	padding: 0.5rem;
	margin: 0.5rem;
}

.amenities-item-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.amenities-item-container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
}

.amenities-item {
	display: flex;
	align-items: center;
}

.amenities-item p {
	margin: 0rem;
	padding: 0.5rem;
}

.open-modal-btn {
	font-size: 14px;
	background-color: transparent;
	color: black;
	border: none;
	text-decoration: underline;
}

.open-modal-btn:hover {
	background-color: transparent;
	color: black;
	font-size: 15px;
	text-decoration: none;
}

.show-amenities {
	border-bottom: 3px solid transparent;
	border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
	padding-bottom: 2rem !important;
	margin-bottom: 2rem !important;
}

.more-amenities-container {
	display: flex;
	align-items: center;
}

.amenities-item-columns {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.amenities-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;
}

.amenities-list {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.amenities-list-item {
	max-width: 300px;
	list-style-type: none;
	overflow-wrap: break-word;
	font-size: 14px;
}
.close-btn-container {
	padding: 0rem 0rem;
	width: 100%;
	position: sticky;
	bottom: 0px;
	display: flex;
	justify-content: flex-start;
}

.house-rules-card {
	padding: 0.5rem;
	margin: 0.5rem;
}

.house-rules-container {
	display: flex;
	padding: 0.5rem 0.5rem 1rem 0.5rem;
	border-bottom: 1px solid transparent;
	border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}

.house-rules-text-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: row;
}

.house-rules-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.damage-incidentals-container {
	display: flex;
	padding: 0.5rem;
	margin: 0.5rem 0rem;
}

.damage-incidentals-text-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.important-information-card {
	padding: 0.5rem;
	margin: 0.5rem;
}

.important-information-card h5 {
	align-self: center;
	margin: 0.5rem 0rem 1rem 0rem;
}

.important-information {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0rem 10rem;
}

.important-information-text p {
	margin: 0;
	padding: 0.5rem;
	width: 75%;
	text-align: start;
	align-self: left;
}

@media only screen and (min-device-width: 993px) and (max-device-width: 1241px) {
	.spaces-item-container {
		flex-direction: column;
	}

	.more-amenities-container {
		flex-direction: column !important;
	}
}

@media only screen and (max-device-width: 3000px) {
	.more-amenities-container {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

@media only screen and (max-device-width: 992px) {
	.amenities-item-container {
		flex-wrap: wrap;
	}
	.important-information {
		padding: 0.5rem;
	}
	.captains-hideaway-header {
		background-position: -100px 0;
	}

	.more-amenities-container {
		flex-direction: column;
	}

	.amenities-item-columns {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.amenities-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 80%;
	}
}

@media only screen and (max-device-width: 776px) {
	.overview {
		flex-direction: row !important;
	}
	.captains-hideaway-card-body div {
		flex-direction: column ;
	}
	.amenities-item-container {
		flex-direction: row !important;
	}
	.captains-hideaway-card-body h3 {
		text-align: center;
		border-bottom: 3px solid transparent;
		border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
		border-image-slice: 1;
		border-image-outset: 0;
		border-image-repeat: stretch;

	}

	.captains-hideaway-card-body {
		border: none;
	}
}
