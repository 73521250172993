.signin-card {
	margin: 2rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: min-content;
}

.signin-header-container {
  margin-bottom: 0.5rem;
  width: 100%;
	border-bottom: 2px solid transparent;
	border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}

.signin-header-container h3 {
	padding: 1rem 0rem 0rem 0rem;
}

.signin-form {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0.5rem;
	margin: 0.5rem;
	border-radius: 6px;
}

.alert-dismissible .btn-close {
    /* position: absolute; */
    top: auto;
    left: auto;
    z-index: 2;
    padding: 0rem 0.5rem;
	
}
