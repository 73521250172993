.contact-card {
	margin: 2rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	box-shadow: #0000001f 6px 6px;
}

.contact-card h3 {
	border-bottom: 2px solid transparent;
	border-image-source: linear-gradient(to right, white, #abccd8, #5f8fa5, #abccd8, white);
	border-image-slice: 1;
	border-image-outset: 0;
	border-image-repeat: stretch;
}
.contact-container {
	display: flex;
	justify-content: center;
}

.contact-components-ctr {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: row ! important;
}

@media only screen and (max-device-width: 1384px) {
	.contact-components-ctr {
		flex-direction: column !important;
	}
}

.contact-form-container {
	min-width: 50%;
}

.contact-form-container h3 {
	margin: 1rem 0rem 0rem 0rem;
	padding: 1rem 0rem 0rem;
}

.contact-form {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0.5rem;
	margin: 0.5rem;
	border-radius: 6px;
}

.contact-form button {
	margin: 0rem 0rem 0.5rem 0rem;
}

.contact-information-link {
	display: flex;
	align-items: center;
	text-decoration: none;
}

.contact-information-link p {
	margin: 0;
	padding: 0.5rem;
	color: black;
}

.contact-header-text {
	padding: 1rem 0rem 0rem 0rem;
}
