.rental-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  
}

.rental-card {
  margin: 1rem;
  padding: 1rem 0rem
}

.rental-description {
  width: 75%;
  text-align: left;
}

.card-title-container {
  border-bottom: 3px solid transparent;
  border-image-source: linear-gradient(to right, white, #ABCCD8, #5f8fa5, #ABCCD8, white);
  border-image-slice: 1;
  border-image-outset: 0;
  border-image-repeat: stretch;
  margin-bottom: 10px;
}


.info-btn-container {
  width: 75%;
  margin-top: 0.5rem;
}

.info-btn {
  padding: 0.5rem;
  background-color: #5f8fa5;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;

}

.info-btn:hover {
  opacity: 0.9;
}

.prop-card-img {
  border-radius: 6px !important;
}
@media only screen and (max-device-width: 776px) {
 .prop-card-img {
  border-radius: 10px !important;
} 
}